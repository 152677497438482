import React, { Component } from "react";
import "./booknow.css";
// import $ from "jquery";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

class BookNow extends Component {

  componentDidMount() {
    // $("#startdate_datepicker").datepicker();
    // $("#enddate_datepicker").datepicker();
  }


  render() {
    const {
      model,
      pickUpTime,
      dropOffTime,
      selectedPickUpTime,
      selectedDropOffTime,
      currentDate,
      firstName,
      lastName,
      email,
      contactNumber,
      onSubmit,
      onFirstNameOnChange,
      onLastNameOnChange,
      onContactNumberOnChange,
      onEmailOnChange,
      onDropOffDateChange,
      onDropOffTimeChange,
      onPickUpDateChange,
      onPickUpTimeChange,
      disableSubmitButton,
      loadingText } = this.props;
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="bookNowModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="bookNowModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form onSubmit={onSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="bookNowModalLabel">
                    Please fill in your details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <FormControl className="firstName">
                        <label htmlFor="firstName">First Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          aria-describedby="emailHelp"
                          placeholder="Enter First Name"
                          value={firstName}
                          onChange={onFirstNameOnChange}
                        />
                        {/* <small id="" className="form-text text-muted">
                        First name is required.
                      </small> */}
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl className="lastName">
                        <label htmlFor="lastName">Last Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={onLastNameOnChange}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group start_date col-md-6">
                      {/* <label htmlFor="lastName">Pick-up date</label>
                      <input
                        className="form-control start_date"
                        type="text"
                        placeholder="mm/dd/yyyy"
                        id="startdate_datepicker" /> */}

                      <TextField
                        id="pickUpDate"
                        label="Pick-up date"
                        type="date"
                        defaultValue={currentDate}
                        fullWidth
                        InputProps={{ inputProps: { min: { currentDate } } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={onPickUpDateChange}
                      />

                      <TextField
                        id="select-pick-up-time"
                        select
                        label="Select pick-up time"
                        value={selectedPickUpTime}
                        onChange={onPickUpTimeChange}
                        SelectProps={{
                          native: true
                        }}
                        fullWidth
                        helperText="Please select your pick-up time"
                        margin="normal"
                      >
                        {pickUpTime.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                    <div className="form-group end_date col-md-6">
                      {/* <label htmlFor="lastName">Drop-off date</label>
                      <input
                        className="form-control end_date"
                        type="text"
                        placeholder="mm/dd/yyyy"
                        id="enddate_datepicker" /> */}

                      <TextField
                        id="dropOffDate"
                        label="Drop-off date"
                        type="date"
                        defaultValue={currentDate}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={onDropOffDateChange}
                      />

                      <TextField
                        id="select-drop-off-time"
                        select
                        label="Select drop-off time"
                        value={selectedDropOffTime}
                        onChange={onDropOffTimeChange}
                        SelectProps={{
                          native: true
                        }}
                        fullWidth
                        helperText="Please select your drop-off time"
                        margin="normal"
                      >
                        {dropOffTime.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                    {/* <div className="start_date input-group mb-4">
                      <input className="form-control start_date" type="text" placeholder="start date" id="startdate_datepicker" />
                      <div className="input-group-append">
                        <span className="fa fa-calendar input-group-text start_date_calendar" aria-hidden="true "></span>
                      </div>
                    </div> */}
                    {/* <div className="end_date input-group mb-4">
                      <input className="form-control end_date" type="text" placeholder="end date" id="enddate_datepicker" />
                      <div className="input-group-append">
                        <span className="fa fa-calendar input-group-text end_date_calendar" aria-hidden="true "></span>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="nationality">Nationality</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        placeholder="Malaysia / Singapore / Etc."
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="ic">Ic / Passport</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ic"
                        placeholder="860101-12-0000 / A02310000"
                      />
                    </div>
                  </div> */}
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <FormControl className="email">
                        <label htmlFor="emailAddress">Email address *</label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailAddress"
                          aria-describedby="emailHelp"
                          placeholder="abc@youremail.com"
                          value={email}
                          onChange={onEmailOnChange}
                        />
                      </FormControl>
                      {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl className="contactNumber">
                        <label htmlFor="contactNumber">Contact Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactNumber"
                          placeholder="010-1234567"
                          value={contactNumber}
                          onChange={onContactNumberOnChange}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <FormControl className="selectedModel">
                        <label htmlFor="selectedModel">Selected Model</label>
                        <input
                          type="text"
                          className="form-control"
                          id="selectedModel"
                          value={model}
                          disabled={true}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="container">
                    <div className="row">
                      <small className="required-text">* Required field</small>
                    </div>
                    <div className="row">
                      <button
                        type="button"
                        className="btn btn-secondary btn-close"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" disabled={disableSubmitButton} className="btn btn-primary">
                        Submit
                      </button>
                      <span className="message">{loadingText}</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BookNow;
