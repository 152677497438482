import React, { Component } from "react";
import "./App.css";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Main from "./components/main";

//#region google analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-131840898-1');
ReactGA.pageview(window.location.pathname + window.location.search);
//#endregion

class App extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Main />
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
