import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./main.css";
import BookNow from "./booknow";
import Messagebox from "./messagebox"
import $ from "jquery";
import * as emailjs from 'emailjs-com';

const pickUpTimeList = [
  {
    value: '00:00',
    label: '12:00 AM'
  },
  {
    value: '01:00',
    label: '1:00 AM'
  },
  {
    value: '02:00',
    label: '2:00 AM'
  },
  {
    value: '03:00',
    label: '3:00 AM'
  },
  {
    value: '04:00',
    label: '4:00 AM'
  },
  {
    value: '05:00',
    label: '5:00 AM'
  },
  {
    value: '06:00',
    label: '6:00 AM'
  },
  {
    value: '07:00',
    label: '7:00 AM'
  },
  {
    value: '08:00',
    label: '8:00 AM'
  },
  {
    value: '09:00',
    label: '9:00 AM'
  },
  {
    value: '10:00',
    label: '10:00 AM'
  },
  {
    value: '11:00',
    label: '11:00 AM'
  },
  {
    value: '12:00',
    label: '12:00 PM'
  },
  {
    value: '13:00',
    label: '1:00 PM'
  },
  {
    value: '14:00',
    label: '2:00 PM'
  },
  {
    value: '15:00',
    label: '3:00 PM'
  },
  {
    value: '16:00',
    label: '4:00 PM'
  },
  {
    value: '17:00',
    label: '5:00 PM'
  },
  {
    value: '18:00',
    label: '6:00 PM'
  },
  {
    value: '19:00',
    label: '7:00 PM'
  },
  {
    value: '20:00',
    label: '8:00 PM'
  },
  {
    value: '21:00',
    label: '9:00 PM'
  },
  {
    value: '22:00',
    label: '10:00 PM'
  },
  {
    value: '23:00',
    label: '11:00 PM'
  }
];

const dropOffTimeList = [
  {
    value: '00:00',
    label: '12:00 AM'
  },
  {
    value: '01:00',
    label: '1:00 AM'
  },
  {
    value: '02:00',
    label: '2:00 AM'
  },
  {
    value: '03:00',
    label: '3:00 AM'
  },
  {
    value: '04:00',
    label: '4:00 AM'
  },
  {
    value: '05:00',
    label: '5:00 AM'
  },
  {
    value: '06:00',
    label: '6:00 AM'
  },
  {
    value: '07:00',
    label: '7:00 AM'
  },
  {
    value: '08:00',
    label: '8:00 AM'
  },
  {
    value: '09:00',
    label: '9:00 AM'
  },
  {
    value: '10:00',
    label: '10:00 AM'
  },
  {
    value: '11:00',
    label: '11:00 AM'
  },
  {
    value: '12:00',
    label: '12:00 PM'
  },
  {
    value: '13:00',
    label: '1:00 PM'
  },
  {
    value: '14:00',
    label: '2:00 PM'
  },
  {
    value: '15:00',
    label: '3:00 PM'
  },
  {
    value: '16:00',
    label: '4:00 PM'
  },
  {
    value: '17:00',
    label: '5:00 PM'
  },
  {
    value: '18:00',
    label: '6:00 PM'
  },
  {
    value: '19:00',
    label: '7:00 PM'
  },
  {
    value: '20:00',
    label: '8:00 PM'
  },
  {
    value: '21:00',
    label: '9:00 PM'
  },
  {
    value: '22:00',
    label: '10:00 PM'
  },
  {
    value: '23:00',
    label: '11:00 PM'
  }
];

class Main extends Component {
  currentDate = this.convertDate(new Date());

  state = {
    models: [],
    pickUpTime: pickUpTimeList,
    dropOffTime: dropOffTimeList,
    selectedPickUpDate: this.currentDate,
    selectedPickUpTime: "12:00",
    selectedDropOffDate: this.currentDate,
    selectedDropOffTime: "12:00",
    currentDate: this.currentDate,
    selectedModel: "",
    selectedRate: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    showMessagebox: false,
    disableSubmitButton: false,
    loadingText: "",
    hideLoader: false
  };
  groupSize = 3;
  displayContent = [];
  subDisplayContent = [];

  constructor(props) {
    super(props);

    // Initialize emailjs
    //TODO: Move to config file
    emailjs.init("user_6RDSuUxbWYBPGGWQko6Mu");
  }

  componentDidMount() {
    // Ajax Call
    //TODO: Move to config file
    fetch(`https://dve79re6hb.execute-api.us-east-1.amazonaws.com/dev/cars`)
      .then(resp => resp.json())
      .then(data => {
        this.setState({ models: data, hideLoader: true });
      })
  }

  renderCarModels() {
    try {
      const partitionArray = (array, size) =>
        array
          .map((e, i) => (i % size === 0 ? array.slice(i, i + size) : null))
          .filter(e => e);

      this.displayContent = [];
      partitionArray(this.state.models, this.groupSize).forEach((product, i) => {
        this.subDisplayContent = [];

        product.forEach((subContent, n) => {
          this.subDisplayContent.push(
            <div className="card-deck" key={subContent._id}>
              <div className="card" style={{ width: "20rem" }}>
                <img
                  className="card-img-top"
                  src={subContent.imagePath}
                  alt="Card"
                />
                <div className="card-body">
                  <h5 className="card-title">{subContent.model}</h5>
                  <p className="card-text">{subContent.rate}</p>
                </div>
                <div className="card-footer">
                  <div className="d-inline p-2">
                    <button
                      data-toggle="modal"
                      data-target="#bookNowModal"
                      className="btn btn-primary"
                      onClick={(e) => { this.bookNowOnClick(e, subContent.model, subContent.rate) }}
                    >
                      Book Now
                  </button>
                  </div>
                  <div className="d-inline p-2">
                    <button className="whatsappButton" onClick={e => this.whatsappOnClick(e, subContent.model)}>
                      <img
                        className="whatsapp"
                        src="/Whatsapp.png"
                        alt="whatsapp"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        });

        this.displayContent.push(
          <div key={i}>
            <div className="row">{this.subDisplayContent}</div>
            <hr />
          </div>
        );
      });
    } catch (e) {
      console.warn(e);
    }
  }

  bookNowOnClick(e, model, rate) {
    this.setState({ selectedModel: model, selectedRate: rate });
  }

  whatsappOnClick(e, model) {
    e.preventDefault();
    let text = `Car Model: ${model}`;
    //TODO: Move to config file
    const url = `https://api.whatsapp.com/send?phone=60165250052&text=${text}`;
    window.open(url, '_blank');
  }

  handleSubmit = (event) => {
    event.preventDefault();

    try {
      this.fieldValidation();

      this.setState({ disableSubmitButton: true, loadingText: "Submitting your request..." });

      let startDate = this.state.selectedPickUpDate;
      let endDate = this.state.selectedDropOffDate;
      let startTime = this.state.selectedPickUpTime;
      let endTime = this.state.selectedDropOffTime;

      let templateParams = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email_address: this.state.email,
        message_html:
          `Good day, I would like to request to rent ${this.state.selectedModel}
      from ${startDate} ${startTime} to ${endDate} ${endTime}.
      Please contact me on ${this.state.contactNumber} or email me at ${this.state.email}.`
      };

      emailjs.send('gmail', 'template_dxpS55ya', templateParams)
        .then((response) => {
          if (response.status === 200) {
            $('#bookNowModal').modal('hide');
            $('#exampleModalCenter').modal('show');

            this.setState({
              selectedModel: "",
              selectedRate: "",
              selectedPickUpDate: this.currentDate,
              selectedPickUpTime: "12:00",
              selectedDropOffDate: this.currentDate,
              selectedDropOffTime: "12:00",
              firstName: "",
              lastName: "",
              email: "",
              contactNumber: "",
              disableSubmitButton: false,
              loadingText: ""
            });

          } else {
            this.setState({ disableSubmitButton: false, loadingText: "Unable to submit your request, please try again." });
          }
          console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
          console.log('FAILED...', error);
          this.setState({ disableSubmitButton: false, loadingText: "Unable to submit your request, please try again." });
        });
    } catch (error) {
      this.setState({ disableSubmitButton: true, loadingText: error });
    }
  }

  handleInputChange = (e, name) => {
    this.setState({ [name]: e.target.value }, () => {
      if (this.state.firstName.length === 0) {
        this.setState({ disableSubmitButton: true, loadingText: "First name is required." });
        return;
      }
      if (this.state.lastName.length === 0) {
        this.setState({ disableSubmitButton: true, loadingText: "Last name is required." });
        return;
      }
      if (this.state.email.length === 0) {
        this.setState({ disableSubmitButton: true, loadingText: "Email is required." });
        return;
      }

      this.setState({ disableSubmitButton: false, loadingText: "" });
    });
  }

  handleSelectedTimeChange = (e, name) => {
    e.preventDefault();
    this.setState({ [name]: e.target.value }, () => {
      // console.log(this.state.selectedPickUpTime);
      // console.log(this.state.selectedDropOffTime);
    });
  }

  handleSelectedDateChange = (e, name) => {
    e.preventDefault();
    this.setState({ [name]: e.target.value }, () => {
      // console.log(this.state.selectedPickUpDate);
      // console.log(this.state.selectedDropOffDate);
    });
  }

  convertDate(date) {
    let yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString();
    let dd = date.getDate().toString();

    let mmChars = mm.split('');
    let ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }

  fieldValidation() {
    if (this.state.firstName.length === 0) {
      let err = "First name is required.";
      throw err;
    }
    if (this.state.lastName.length === 0) {
      let err = "Last name is required.";
      throw err;
    }
    if (this.state.email.length === 0) {
      let err = "Email is required.";
      throw err;
    }
  }

  render() {
    this.renderCarModels();

    return (
      <React.Fragment>
        <main role="main">
          <div className="jumbotron jumbotron-fluid ipcs">
            <div className="container">
              <p className="display-4 text-warning font-weight-bold">
                Discover Sabah the unique way
              </p>
              <p className="backgroundText">
                Our company provides you with the best car rental services in
                Kota Kinabalu Sabah, tailor-made for your needs. At DISCOVER
                KINABALU, we make it happened for you. We love to travel. And
                the best things about travel is the journey. The roadtrips where
                we can discover the people and nature at our own pace. It is a
                gateaway from all the hustle and bustle of everyday life.
              </p>
            </div>
          </div>

          <div className="container">
            <div className={this.state.hideLoader ? "hidden" : "loader"}><CircularProgress color="secondary" /></div>
            {this.displayContent}
          </div>

          <BookNow
            onSubmit={this.handleSubmit}
            onFirstNameOnChange={e => this.handleInputChange(e, "firstName")}
            onLastNameOnChange={e => this.handleInputChange(e, "lastName")}
            onContactNumberOnChange={e => this.handleInputChange(e, "contactNumber")}
            onEmailOnChange={e => this.handleInputChange(e, "email")}
            onDropOffDateChange={e => this.handleSelectedDateChange(e, "selectedDropOffDate")}
            onDropOffTimeChange={e => this.handleSelectedTimeChange(e, "selectedDropOffTime")}
            onPickUpDateChange={e => this.handleSelectedDateChange(e, "selectedPickUpDate")}
            onPickUpTimeChange={e => this.handleSelectedTimeChange(e, "selectedPickUpTime")}
            model={this.state.selectedModel}
            pickUpTime={this.state.pickUpTime}
            dropOffTime={this.state.dropOffTime}
            selectedPickUpTime={this.state.selectedPickUpTime}
            selectedDropOffTime={this.state.selectedDropOffTime}
            currentDate={this.state.currentDate}
            rate={this.state.selectedRate}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            contactNumber={this.state.contactNumber}
            showMessagebox={this.state.showMessagebox}
            disableSubmitButton={this.state.disableSubmitButton}
            loadingText={this.state.loadingText}
          />
        </main>

        <Messagebox />
      </React.Fragment>
    );
  }
}

export default Main;
