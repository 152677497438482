import React, { Component } from "react";
import "./footer.css";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <hr />
          <div className="alert alert-light" role="alert">
            <h4 className="alert-heading">&copy; SABAH CAR RENTAL. 2018</h4>
            <p className="mb-0 contactUs">
              <span className="d-block p-2"><b>Contact us:</b> +60165250052 / +601155030052</span>
            </p>
            <div className="container email">
              <div className="row testpadding">
                <div className="col-sm-4">
                  <b>Email:</b> discoverkinabalucarrental@gmail.com
                </div>
                <div className="col-sm-8">
                  <b>Follow us on:</b>
                  <a href="https://www.facebook.com/Discover-Kinabalu-Car-Rental-2328611273831071/">
                    <img
                      className="followUs"
                      src="/facebook.png"
                      alt="facebook"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment >
    );
  }
}

export default Footer;
