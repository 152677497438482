import React, { Component } from "react";
import "./navbar.css";

class Navbar extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <picture className="mr-1">
            <img
              alt="discover kinabalu logo.png"
              data-type="image"
              src="./discoverkinabalulogo.jpg"
            />
          </picture>
          <a className="navbar-brand d-none d-lg-block" href="/">
            <h5>DISCOVER KINABALU CAR RENTAL</h5>
          </a>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
